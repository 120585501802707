import SectionSplitter from "../components/SectionSplitter";
import {motion} from 'framer-motion';
import {useForm} from "react-hook-form";
import useMediaQuery from "../hooks/useMediaQuery";
import ContactImg from "../assets/contact.jpg";


const Contact = () => {
    const isAboveMediumScreens = useMediaQuery('(min-width: 1060px)');
    const {register, trigger, formState: {errors}} = useForm();
    const onSubmit = async (e) => {
        const isValid = await trigger();
        if (!isValid) {
            e.preventDefault();
        }
    }
    return(
        <section id="kontakt" className="pt-10 pb-24">
            <div className='md:flex md:justify-between md:gap-16 md:mt-32 mt-5'>
            <motion.div
            className='md:w-1/3 basis-2/4'
             initial="hidden"
             whileInView="visible"
             viewport={{once: true, amount: 0.5}}
             transition={{duration: 0.5}}
             variants={{
                 hidden: {opacity: 0, x: -50},
                 visible: {opacity: 1, x: 0}
             }}
            >
            <h2 className='font-playfair font-semibold text-4xl mb-5'>
                Kontakt <span className='text-blue'>Mig</span>
            </h2>
            <SectionSplitter width="w-1/4" />
            <p className='mt-10 mb-7'>
                Du kan få fat på mig her:
            </p>
            </motion.div>
            <div className='flex justify-center basis-2/4 z-10 '>
            {isAboveMediumScreens ? (
                <motion.div
                className=''
                 initial="hidden"
                 whileInView="visible"
                 viewport={{once: true, amount: 0.5}}
                 transition={{duration: 0.5}}
                 variants={{
                     hidden: {opacity: 0, x: 50},
                     visible: {opacity: 1, x: 0}
                 }}
                >
                <div
                className='relative z-0 ml-20 before:absolute before:-top-20 before:-left-20  
                before:max-w-[400px] before:h-full before:border-2 before:border-blue before:z-[-1] before:w-full'
                >
                    <img
                    className="hover:filter hover:saturate-200 transition duration-500 z-10 w-full
                    max-w-[400px] md:mx-w-[600px]  object-cover"
                    src={ContactImg} alt="profile" />
                </div>

                </motion.div>
                ) : (  <></> )}
            </div>
        </div>
        <div className="md:flex md:justify-between gap-16 mt-5">
             <motion.div
                className='w-full md:w-1/2'
                 initial="hidden"
                 whileInView="visible"
                 viewport={{once: true, amount: 0.1}}
                 transition={{duration: 0.5}}
                 variants={{
                     hidden: {opacity: 0, y: -50},
                     visible: {opacity: 1, y: 0}
                 }}
                >
                <form
                target="_blank"
                onSubmit={onSubmit}
                action="https://formsubmit.co/08dca9c40aded672e7bac8a580ffa02f"
                method="POST"
                >
                    <input 
                    className="w-full bg-white font-semibold placeholder-opaque-black p-3 text-black"
                    type="text"
                    placeholder="Navn"
                    {...register("name", {required: true, maxLength: 100})}
                     />
                     {errors.name && (<p className="text-red mt-1">
                       {errors.name.type === "required" && "Navn er påkrævet"}
                        {errors.name.type === "maxLength" && "Navn må ikke være længere end 100 tegn"}
                        </p>)}

                        <input 
                    className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 text-black"
                    type="email"
                    placeholder="Email"
                    {...register("email", {required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,})}
                     />
                     {errors.email && (<p className="text-red mt-1">
                       {errors.email.type === "required" && "Email er påkrævet"}
                        {errors.email.type === "pattern" && "Email er ikke gyldig"}
                        </p>)}

                        <textarea 
                    className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 text-black"
                    type="text"
                    placeholder="Besked"
                    rows="4"
                    columns="50"
                    {...register("message", {required: true, maxLength: 2000})}
                     />
                     {errors.message && (<p className="text-red mt-1">
                       {errors.message.type === "required" && "Besked er påkrævet"}
                        {errors.message.type === "maxLength" && "Beskeden må ikke være længere end 2000 tegn"}
                        </p>)}

                        <button
                        type="submit"
                        className="p-5 bg-blue font-semibold text-deep-blue mt-5 hover:bg-white transition duration-300"
                        >SEND MIG EN BESKED</button>
                </form>

                </motion.div>
        </div>
        </section>
    )
}


export default Contact;