import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer className="bg-deep-blue py-10">
            <div className="container mx-auto">
                <div className="flex justify-center">
                    <SocialMediaIcons />
                </div>
                <div className="flex justify-center mt-5">
                    <p className="text-white text-center">
                        © {year} Morten Gedsted Hansen
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;