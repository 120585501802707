import { useState } from "react";
import Navbar from "./scenes/Navbar";
import Landing from "./scenes/Landing";
import SectionSplitter from "./components/SectionSplitter";
import MySkills from "./scenes/MySkills";
import Projects from "./scenes/Projects";
import Contact from "./scenes/Contact";
import Footer from "./scenes/Footer";

function App() {
  const [selectedPage, setSelectedPage] = useState("home");

  return (
    <div className="app bg-deep-blue">
      <Navbar selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
      <div className="w-5/6 mx-auto md:h-full">
        <Landing setSelectedPage={setSelectedPage} />
      </div>
      <div className="w-5/6 mx-auto">
        <MySkills />
      </div>
      <div className="w-5/6 mx-auto">
        <Projects />
      </div>
      <div className="w-5/6 mx-auto">
        <Contact />
      </div>
      <SectionSplitter />
      <Footer />
    </div>
  );
}

export default App;
