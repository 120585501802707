import {BsLinkedin} from "react-icons/bs";
import {BsGithub} from "react-icons/bs";

const SocialMediaIcons = () => {

return (
    <div
    className="flex justify-center md:justify-start my-10 gap-7"
    >
        <a 
        className="hover:opacity-50 transition duration-500"
        href="https://www.linkedin.com/in/morten-gedsted-h-07382688/"
        target="_blank"
        rel="noreferrer"
        >
            <BsLinkedin size={40} />
        </a>
        <a 
        className="hover:opacity-50 transition duration-500 "
        href="https://github.com/Mortengh1"
        target="_blank"
        rel="noreferrer"
        >
            <BsGithub size={42} />
        </a>
    </div>
)

}

export default SocialMediaIcons;