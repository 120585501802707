import SectionSplitter from "../components/SectionSplitter";
import Skill from "../components/Skill";
import { motion } from "framer-motion";

const MySkills = () => {
  return (
    <section
      id="skills"
      className="pt-10 pb-24 md:flex md:justify-between md:items-center md:gap-10"
    >
      <div className="md:flex md:justify-between md:gap-16 mt-5 md:order-2 h-fit ">
        <motion.div
          className=""
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <h2 className="font-playfair font-semibold text-4xl mb-5">
            Mine <span className="text-blue">Skills</span>
          </h2>
          <SectionSplitter width="w-1/4" />
          <p className="mt-10 mb-7">
            Her er en oversigt over de forskellige sprog og redskaber jeg
            arbejder med.
          </p>
        </motion.div>
      </div>
      <div className="md:flex md:justify-between mt-16 gap-32 md:w-1/2 md:order-1">
        <motion.div
          className="grid md:grid-cols-4 grid-cols-2 w-full gap-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <Skill icon="/assets/svgs/php-icon.svg" skill="PHP" />
          <Skill icon="/assets/svgs/react-icon.svg" skill="React" />
          <Skill icon="/assets/svgs/javascript-icon.svg" skill="JavaScript" />
          <Skill icon="/assets/svgs/jquery-icon.svg" skill="jQuery" />
          <Skill icon="/assets/svgs/html-icon.svg" skill="HTML" />
          <Skill icon="/assets/svgs/css-icon.svg" skill="CSS" />
          <Skill icon="/assets/svgs/sass-icon.svg" skill="SCSS" />
          <Skill icon="/assets/svgs/tailwind-icon.svg" skill="Tailwind" />
          <Skill icon="/assets/svgs/wordpress-icon.svg" skill="Wordpress" />
          <Skill icon="/assets/svgs/sql-icon.svg" skill="SQL" />
          <Skill icon="/assets/svgs/mysql-icon.svg" skill="MySQL" />

          {/* <Skill icon="/assets/svgs/remix-icon.svg" skill="Remix" /> */}
        </motion.div>
      </div>
    </section>
  );
};

export default MySkills;
