import { motion } from "framer-motion";
import useMediaQuery from "../hooks/useMediaQuery";
import AnchorLink from "react-anchor-link-smooth-scroll";
import ProfilePic from "../assets/morten_hansen-2.jpg";
import SocialMediaIcons from "../components/SocialMediaIcons";

const Landing = ({ setSelectedPage }) => {
  const isAboveSmallScreens = useMediaQuery("(min-width: 1060px)");

  return (
    <section
      id="hjem"
      className="md:flex md:justify-between md:items-center gap-16 md:h-[100vh] py-10"
    >
      {/* IMAGE SECTION */}
      <div className="md:order-2 flex justify-center basis-2/4 z-10 mt-16 md:mt-0">
        {isAboveSmallScreens ? (
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: 50 },
              visible: { opacity: 1, x: 0 },
            }}
          >
            <div
              className="relative z-0 ml-20 before:absolute before:-top-20 before:-left-20 before:rounded-t-[400px] 
                 before:max-w-[400px] before:h-full before:border-2 before:border-blue before:z-[-1] before:w-full"
            >
              <img
                className="hover:filter hover:saturate-200 transition duration-500 z-10 w-full
                    max-w-[400px] md:mx-w-[600px] rounded-t-[400px]"
                src={ProfilePic}
                alt="profile"
              />
            </div>
          </motion.div>
        ) : (
          <img
            className="hover:filter hover:saturate-200 transition duration-500 z-10 w-full
                max-w-[400px] md:mx-w-[600px] rounded-t-[400px]"
            src={ProfilePic}
            alt="profile"
          />
        )}
      </div>
      {/* TEXT SECTION */}
      <div className="z-30 basis-2/4 mt-12 md:mt-0  justify-center">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <h1 className="text-6xl font-playfair z-10 text-center md:text-start">
            Morten Gedsted Hansen
          </h1>
          <p className="mt-10 mb-7 text-center md:text-start text-3xl text-blue">
            Webudvikler
          </p>
        </motion.div>
        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <AnchorLink
            className="rounded-r-sm bg-blue py-0.5 pr-0.5 pl-0.5 transition duration-500 hover:bg-white"
            onClick={() => setSelectedPage("kontakt")}
            href="#kontakt"
          >
            <div
              className="hover:bg-white text-deep-blue  bg-blue transition duration-500 w-full h-full 
                      flex items-center justift-center px-10 py-3"
            >
              Kontakt
            </div>
          </AnchorLink>
          <AnchorLink
            className="rounded-r-sm bg-blue py-0.5 pr-0.5"
            onClick={() => setSelectedPage("projekter")}
            href="#projekter"
          >
            <div
              className="bg-deep-blue hover:text-deep-blue hover:bg-blue transition duration-500 w-full h-full 
                      flex items-center justift-center font-playfair px-10"
            >
              Se projekter
            </div>
          </AnchorLink>
        </motion.div>
        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <SocialMediaIcons />
        </motion.div>
      </div>
    </section>
  );
};

export default Landing;
