import AnchorLink from "react-anchor-link-smooth-scroll";
import Scrollspy from "react-scrollspy";

const Link = ({ selectedPage, setSelectedPage, page }) => {
  const lowerCasePage = page.toLowerCase();
  return (
    <AnchorLink
      className={`${selectedPage === lowerCasePage ? "" : ""}
        hover:text-blue transition-colors duration-500 ease-in-out
        `}
      href={`#${lowerCasePage}`}
      onClick={() => setSelectedPage(lowerCasePage)}
    >
      {page}
    </AnchorLink>
  );
};

const Navbar = ({ selectedPage, setSelectedPage }) => {
  return (
    <nav className="z-40 w-fit left-1/2 transform -translate-x-1/2 fixed bottom-10 ">
      <div className="flex items-center justify-center mx-auto w-5/6">
        {/* DESKTOP NAV */}
        <div className="flex justify-between gap-16 font-opensans text-sm font-semibold p-8 backdrop-blur bg-white/20 rounded-full">
          <Scrollspy
            items={["hjem", "skills", "projekter", "kontakt"]}
            currentClassName="text-blue"
            className="flex justify-between gap-6 mb:gap-16 font-opensans text-sm font-semibold "
          >
            <li>
              <Link
                page="HJEM"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </li>
            <li>
              <Link
                page="SKILLS"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </li>
            <li>
              <Link
                page="PROJEKTER"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </li>
            <li>
              <Link
                page="KONTAKT"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
              />
            </li>
          </Scrollspy>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
