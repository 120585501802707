const Skill = ({ icon, skill }) => {
  return (
    <div className="relative text-center flex flex-col items-center">
      <div
        className="z-10 h-fit w-fit relative bg-white p-5 rounded-full shadow-2xl shadow-gray-600 border-2 border-deep-blue 
    group hover:bg-transparent transition duration-300 hover:border-blue  hover:shadow-md shadow-inner"
      >
        <img
          className="w-[100px] md:w-[100px] md:h-full h-full object-contain object-center "
          src={icon}
          alt={skill}
        />
      </div>
      <p className="pt-2 font-bold"> {skill}</p>
    </div>
  );
};

export default Skill;
