import SectionSplitter from "../components/SectionSplitter";
import { motion } from "framer-motion";
import useMediaQuery from "../hooks/useMediaQuery";
import ProjectsImg from "../assets/projects.jpg";
import Project from "../components/Project";
import hotelklostergaard from "../assets/hotelklostergaard.png";
import kanneworffviuff from "../assets/kanneworffviuff.png";
import shlbegravelser from "../assets/shlbegravelser.png";
import onecommunity from "../assets/1community.png";
import flmarketing from "../assets/flmarketing.dk.png";
import urnen from "../assets/urnen.png";

const Projects = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="projekter" className="pt-10 pb-24">
      <div className="md:flex md:justify-between md:gap-16 mt-5 md:mt-32">
        <motion.div
          className="md:w-1/3 basis-2/4"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <h2 className="font-playfair font-semibold text-4xl mb-5">
            Mine <span className="text-blue">Projekter</span>
          </h2>
          <SectionSplitter width="w-1/4" />
          <p className="mt-10 mb-7">
            Her ses nogle udvalgte projekter som jeg har lavet.
          </p>
        </motion.div>
        <div className="flex justify-center basis-2/4 z-10 ">
          {isAboveMediumScreens ? (
            <motion.div
              className=""
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.5 }}
              variants={{
                hidden: { opacity: 0, x: 50 },
                visible: { opacity: 1, x: 0 },
              }}
            >
              <div
                className="relative z-0 ml-20 before:absolute before:-top-20 before:-left-20  
                before:max-w-[400px] before:h-full before:border-2 before:border-blue before:z-[-1] before:w-full"
              >
                <img
                  className="hover:filter hover:saturate-200 transition duration-500 z-10 w-full
                    max-w-[400px] md:mx-w-[600px]  object-cover"
                  src={ProjectsImg}
                  alt="profile"
                />
              </div>
            </motion.div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <motion.div
        className=""
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <div className="flex flex-col md:grid md:grid-cols-3 md:gap-16 mt-16 gap-16">
          <Project
            img={hotelklostergaard}
            title="Hotelklostergaarden.dk"
            by="Brand by Hand"
            byLink="https://brandbyhand.dk/"
            link="https://hotelklostergaarden.dk"
            tool1="/assets/svgs/wordpress-icon.svg"
            tool2="/assets/svgs/php-icon.svg"
            tool3="/assets/svgs/sass-icon.svg"
            tool4="/assets/svgs/jquery-icon.svg"
            tool5="/assets/svgs/mysql-icon.svg"
          />
          <Project
            img={kanneworffviuff}
            title="kanneworffviuff.dk"
            by="Brand by Hand"
            byLink="https://brandbyhand.dk/"
            link="https://kanneworffviuff.dk/"
            tool1="/assets/svgs/wordpress-icon.svg"
            tool2="/assets/svgs/php-icon.svg"
            tool3="/assets/svgs/sass-icon.svg"
            tool4="/assets/svgs/jquery-icon.svg"
            tool5="/assets/svgs/mysql-icon.svg"
          />
          <Project
            img={shlbegravelser}
            title="shlbegravelser.dk"
            by="Brand by Hand"
            byLink="https://brandbyhand.dk/"
            link="https://shlbegravelser.dk/"
            tool1="/assets/svgs/wordpress-icon.svg"
            tool2="/assets/svgs/php-icon.svg"
            tool3="/assets/svgs/sass-icon.svg"
            tool4="/assets/svgs/jquery-icon.svg"
            tool5="/assets/svgs/mysql-icon.svg"
          />
          <Project
            img={onecommunity}
            title="1community.dk"
            by="Brand by Hand"
            byLink="https://brandbyhand.dk/"
            link="https://1community.dk/"
            tool1="/assets/svgs/wordpress-icon.svg"
            tool2="/assets/svgs/php-icon.svg"
            tool3="/assets/svgs/sass-icon.svg"
            tool4="/assets/svgs/jquery-icon.svg"
            tool5="/assets/svgs/mysql-icon.svg"
          />
          <Project
            img={flmarketing}
            title="flmarketing.dk"
            by="Brand by Hand"
            byLink="https://brandbyhand.dk/"
            link="https://flmarketing.dk/"
            tool1="/assets/svgs/wordpress-icon.svg"
            tool2="/assets/svgs/php-icon.svg"
            tool3="/assets/svgs/sass-icon.svg"
            tool4="/assets/svgs/jquery-icon.svg"
            tool5="/assets/svgs/mysql-icon.svg"
          />
          <Project
            img={urnen}
            title="søhøjurnen.dk"
            by="Brand by Hand"
            byLink="https://brandbyhand.dk/"
            link="https://xn--shjurnen-54ab.dk/"
            tool1="/assets/svgs/wordpress-icon.svg"
            tool2="/assets/svgs/php-icon.svg"
            tool3="/assets/svgs/sass-icon.svg"
            tool4="/assets/svgs/jquery-icon.svg"
            tool5="/assets/svgs/mysql-icon.svg"
          />
        </div>
      </motion.div>
    </section>
  );
};

export default Projects;
