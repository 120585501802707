const Project = ({
  title,
  by,
  img,
  link,
  byLink,
  tool1,
  tool2,
  tool3,
  tool4,
  tool5,
}) => {
  return (
    <div>
      <a href={link} className="group" target="_blank" rel="noreferrer">
        <p className="group-hover:text-blue text-xl font-playfair pb-5 transition duration-300">
          {title}
        </p>
        <div className="border-2 border-transparent group-hover:border-blue transition duration-300">
          <img
            className="group-hover:opacity-50 transition duration-300  object-contain"
            src={img}
            alt={title}
          />
        </div>
      </a>
      {by ? (
        <p className="pt-5">
          Som ansat hos:{" "}
          <a
            href={byLink}
            target="_blank"
            rel="noreferrer"
            className="hover:text-blue transition duration-300"
          >
            {by}
          </a>
        </p>
      ) : null}
      {tool1 || tool2 || tool3 || tool4 || tool5 ? (
        <p className="pt-5 flex gap-2 items-center">
          Lavet med:{" "}
          {tool1 && <img className="w-8 h-8" src={tool1} alt="Redskab" />}
          {tool2 && <img className="w-8 h-8" src={tool2} alt="Redskab" />}
          {tool3 && <img className="w-8 h-8" src={tool3} alt="Redskab" />}
          {tool4 && <img className="w-8 h-8" src={tool4} alt="Redskab" />}
          {tool5 && <img className="w-8 h-8" src={tool5} alt="Redskab" />}
        </p>
      ) : null}
    </div>
  );
};

export default Project;
